<template>
    <div class="p-3">
        <!--  title  -->
        <h4>{{$t('cart.cart')}}</h4>

        <!--  cart delivery options  -->
        <div class="cart-delivery bg-primary mb-3 p-2 d-flex align-content-start">
            <b-icon-truck variant="secondary" font-scale="2" class="ml-2 mr-3"></b-icon-truck>
            <!-- delivery place -->
            <b-form-select
                id="cart.deliveryTime"
                v-model="record.delivery_place"
                :options="data.delivery_place"
                class="mb-2 mr-sm-2 mb-sm-0"
                size="sm"
                :title="$t('cart.choose_delivery_place_tooltip')"
                style="max-width: 150px"
            >
            </b-form-select>
            <!-- delivery date -->
            <b-form-select
                id="cart.deliveryDate"
                v-model="record.delivery_date"
                :options="data.delivery_date"
                class="mb-2 mr-sm-2 mb-sm-0"
                size="sm"
                :title="$t('cart.choose_delivery_date_tooltip')"
                style="width: 150px"
            >
            </b-form-select>
            <!-- delivery time -->
            <b-form-select
                id="cart.deliveryTime"
                v-model="record.delivery_time"
                :options="data.delivery_time"
                class="mb-2 mr-sm-2 mb-sm-0"
                size="sm"
                :title="$t('cart.choose_delivery_time_tooltip')"
                style="width: 150px"
            >
            </b-form-select>
            <!-- delivery options text -->
            <h5 class="ml-auto mt-1 text-black-50 d-none d-lg-block">{{$t('cart.delivery_options')}}</h5>
            <!-- delivery detail -->
            <b-button>info delivery</b-button>
        </div>

        <!-- cart products and calculation -->
        <b-row>
            <!-- left part -->
            <b-col cols="12" md="12" lg="12" xl="8" class="mb-3">
                <!-- product -->
                <b-card
                    class="shadow"
                >
                    <b-row>
                        <b-col cols="12" md="12" lg="9" xl="9">
                            <b-media>
                                <template #aside>
                                    <img src="@/assets/images/temp/carousel/farm05.jpg" class="cart-img" alt="product">
                                </template>

                                <b-card-sub-title>Kmetija Jencinger</b-card-sub-title>
                                <b-card-title>Domače testenine z bučnimi semeni</b-card-title>
                                <p class="mb-sm-1 mb-md-3 mb-lg-3">12 kg leseni zabojček</p>
                                <b-button variant="link" class="text-danger pl-0" size="sm">
                                    {{$t('form.remove')}}
                                </b-button>
                            </b-media>
                        </b-col>
                        <b-col cols="12" md="12" lg="3" xl="3">
                            <b-row class="mt-md-3">
                                <b-col cols="4" md="4" lg="12" xl="12"
                                       class="text-center mt-sm-1 mt-md-1 text-black-50">
                                    12,00 €
                                </b-col>
                                <b-col cols="4" md="4" lg="12" xl="12" class="text-center mb-lg-2 mt-lg-1">
                                    <b-form-spinbutton
                                        size="sm"
                                        placeholder="0"
                                        min="0"
                                        max="1000"
                                        class="mx-auto"
                                        style="max-width: 130px"
                                    ></b-form-spinbutton>
                                </b-col>
                                <b-col cols="4" md="4" lg="12" xl="12"
                                       class="text-center mt-sm-1 mt-md-1 font-weight-bold h5">
                                    12,00 €
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>

            <!-- right part -->
            <b-col cols="12" md="12" lg="12" xl="4">
                <!-- izračun -->
                <b-card
                    class="shadow mb-3"
                    title=""
                >
                    <div class="d-flex justify-content-between font-weight-bold">
                        <b-card-text>{{$t('cart.subtotal')}}:</b-card-text>
                        <b-card-text>12,00 €</b-card-text>
                    </div>
                    <div class="d-flex justify-content-between mb-0">
                        <b-card-text>{{$t('cart.delivery')}}:</b-card-text>
                        <b-card-text>5,00 €</b-card-text>
                    </div>
                    <div class="d-flex justify-content-between mb-0">
                        <b-card-text>{{$t('cart.tax')}}:</b-card-text>
                        <b-card-text>0,00 €</b-card-text>
                    </div>
                    <hr class="mt-1">
                    <div class="d-flex justify-content-between mt-4">
                        <b-card-text class="h5">{{$t('cart.total')}}:</b-card-text>
                        <b-card-text class="font-weight-bold h5">12,00 €</b-card-text>
                    </div>


                </b-card>
                <!-- button Buy -->
                <b-button
                    size="lg"
                    class="shadow w-100"
                    variant="primary"
                >
                    {{$t('cart.confirm')}}
                </b-button>
                <!-- disclaimer -->
                <b-card
                    class="shadow mb-3"
                    title=""
                >
                    <div class="d-flex justify-content-center">
                        <b-card-text>{{$t('cart.disclaimer')}}</b-card-text>
                    </div>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>

export default {
    name: "Cart_deprecated",
    components: {},
    props: {},
    data() {
        return {
            valid: null,
            data: {
                delivery_date: [
                    {
                        value: null,
                        text: this.$t('cart.choose_delivery_date_placeholder'),
                    },
                    {
                        value: 1,
                        text: 'Danes, 25. 4. 2021',
                    },
                    {
                        value: 2,
                        text: 'Četrtek, 12. 5. 2021',
                    },
                ],
                delivery_time: [
                    {
                        value: null,
                        text: this.$t('cart.choose_delivery_time_placeholder'),
                    },
                    {
                        value: 1,
                        text: '15:00-17:00',
                    },
                    {
                        value: 2,
                        text: '17:00-19:00',
                    },
                ],
                delivery_place: [
                    {
                        value: null,
                        text: this.$t('cart.choose_delivery_place_placeholder'),
                    },
                    {
                        value: 1,
                        text: 'Koper',
                    },
                    {
                        value: 2,
                        text: 'Murska Sobota',
                    },
                    {
                        value: 3,
                        text: 'Celje',
                    },
                    {
                        value: 4,
                        text: 'Ljutomer',
                    },
                ],
            },
            record: {
                basic: {},
                delivery_date: null,
                delivery_time: null,
                delivery_place: null,
                quantity: 0,
            }
        }
    },
    mounted() {
    },
    methods: {},
    computed: {},
    filters: {},
    watch: {}
}
</script>

<style scoped>
</style>
